@font-face {
  font-family: 'MonaspaceNeon';
  src: local('MonaspaceNeon'), url(./fonts/MonaspaceNeon-Regular.woff) format('woff');
}

@font-face {
  font-family: 'MonaspaceKrypton';
  src: local('MonaspaceKrypton'), url(./fonts/MonaspaceKrypton-Regular.woff) format('woff');
}

.App {
    margin: 40px 40px;
    font-family: MonaspaceNeon;
}

.flexRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: normal;
    align-content: normal;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: normal;
    align-content: normal;
}

.flexRowBox {
    display: block;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
}

.flexColumnBox {
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: stretch;
    order: 0;
}

.boxWrapper {
    margin-left: 20px;
    margin-right: 20px;
}

textarea {
    border: 1px solid black;
    outline: 0px none transparent;
    resize: none;
    padding: 10px;

    height: 300px;
    box-sizing: border-box;
    width: 100%;

    font-family: MonaspaceNeon;
}

textarea:focus {
    outline: 0
}

.queryTitle {
    margin-top: 2px;
    font-weight: 300;
}

.queryLoading {
    width: 16px;
    line-height: 16px;
    vertical-align: middle;
    margin-right: 5px;
}

Button {
    background-color: white;
    border: 1px solid black;
    margin-right: 10px;
    padding-top: 5px;
    margin-top: 8px;
    font-weight: 300;
    font-family: MonaspaceNeon;
}

Button:hover {
    color: white;
    background-color: black;
    font-weight: 500;
    fill: white;
}

Button:disabled:hover {
    color: gray;
    background-color: white;
}

Button:disabled {
    color: gray;
    background-color: white;
}

.panelHeader {
    border: 1px solid black;
    text-align: left;
    border-bottom: 0;
    /* margin-top: 20px; */
}

.light {
    font-weight: 300;
}

.title {
    font-family: MonaspaceNeon;
    font-weight: 500;
}

.heavy {
    font-weight: 500;
}

.configBox {
    border: 1px solid black;
}

.configBox div {
    padding: 5px;
}

.fixedHeight {
    height: 200px;
}

.minFixedHeight {
    min-height: 100%;
}

.bottomRow {
    border: 1px solid red;
    margin-top: 40px;
}

.reactflowContainer {
    width: calc(100%);
    height: 400px;

    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
}

.tabPicker Button {
    border-bottom: 0px;
    padding: 5px;
}

.tabPicker Button.selected {
    border-bottom: 1px solid white;
    margin-bottom: -1px;
}

.react-flow__handle {
  opacity: 0;
}

.react-flow__node-operator {
  min-width: 150px;
  border: 1px solid #000;
  background: white;
}

.react-flow__node-operator label {
  display: block;
  color: #777;
  font-size: 12px;
}

.operator-title {
    font-family: MonaspaceNeon;
    border-bottom: 1px solid black;
    padding: 5px;
    margin: 0;
    text-align: center;

}

.operator-stats ul {
    font-size: 12px;
    list-style-type: none;
    padding: 0;
    margin: 5px;
}

.queryError {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid red;
    background-color: #DC000088;
    font-size: 12px;
}

.queryStatus {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #DEDEDE;
    background-color: #EFEFEF;
    font-size: 12px;
}

.lineDetail {
    padding: 0px;
    margin: 0px;
}

.line-numbers {
    color: #000000AA !important;
}

.active-line-number {
    color: #000000 !important;
    font-weight: bold;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

.styled-dropdown-control {
    width: 100px !important;
    height: 24px !important;
    font-size: 12px !important;
    border: 1px solid black !important;
    border-radius: 0 !important;
    color: black !important;

    padding: 5px !important;
}

.Dropdown-arrow {
    border-color: #000 transparent transparent !important;
    top: 10px !important;
}

.Dropdown-menu {
    border: 1px solid black !important;
}

.Dropdown-option {
    color: black !important;
    font-size: 12px !important;
}

.Dropdown-option.is-selected {
    background-color: #CBC3E3 !important;
}

.configBox circle {
    r: 2px !important;
}

.volumeSlider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  outline: none; /* Remove outline */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  background: black;

  position: relative;
  bottom: 6px;

  width: 50px;
  height: 2px;
}

.volumeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%; 
  background: black;
  cursor: pointer;
}

.volumeSlider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: black;
  cursor: pointer;
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.fullScreenViz {
    position: fixed;
    left: 20%;
    top: 20%;
    width: calc(100vw - 40%);
    height: 400px;
    min-height: 400px;
    z-index: 999;
    background-color: white;

    box-shadow: 0px 0px 400px 100px #ffffff;

    /* Account for panel header height */
    padding-bottom: 36px;
}

.regularViz {
    min-height: 200px;
    height: 200px;
}
